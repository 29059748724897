import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import React from "react";

const PopularCrafts = () => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          marginTop: 10,
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Saudi Popular Crafts
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}

      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/crafts/1.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Northern region</Text> Weaving
            is the main craft in the northern region.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/crafts/2.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Eastern region</Text> exquisite
            jewelry creation to intricate woodcarving and wall painting by the
            local Asiri people,
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/crafts/3.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Western region</Text> They
            create art and beautiful patterns.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/crafts/4.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5, marginBottom: 20 }}>
            <Text style={{ fontWeight: "800" }}>Central region</Text> Poetry is
            one of the crafts of the central region.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default PopularCrafts;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
});
