import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import React from "react";

const PopularFood = () => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          fontSize: 24,
          textAlign: "center",
        }}
      >
        Saudi Popular Food
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}

      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View>
          <Text style={{ color: "white", fontSize: 16, fontWeight: "600" }}>
            Saudi traditional food
          </Text>
          <Text style={{ color: "white" }}>
            Saudi food, many of the tribes living in these provinces were living
            in relative isolation since ancient times, and overtime, they
            developed their own cuisine using the local food items available in
            the area which they inhabited. The variety of traditional foods in
            the Kingdom is remarkable, with each province having something
            different to offer. Here are the traditional foods from all around
            Saudi.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/1.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Khamir – Jazan</Text> This
            southern delight is a type of bread that is made from ground corn
            grains. It is made by kneading the dough and leaving it overnight.
            The following day the dough is baked in an oven, resulting in a
            bread that can be accompanied with any curry. This baked bread is
            still popular among the semi-urban and rural settlements in the
            region.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/2.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Haneeth – Aseer</Text> Popular
            on the Tihama side of Aseer, this meat dish is the golden jewel of
            Aseer’s cuisine. The haneeth is made by chopping mutton into medium
            sized pieces and placing it in a muhannath. The muhannath is a
            traditional oven made from stone. Branches of trees are then placed
            inside so that the burning stones are separated from the meat. Then
            sand is used to cover the muhannath so that steam doesn’t escape.
            After a few hours of cooking the meat is taken out from the oven and
            salt is sprinkled before serving. Right from the first bite you will
            feel as if all the effort that was put into making the dish was
            worth it.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/3.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Wishaiq – Jouf</Text> The people
            living in the Jouf region have great diversity in the food they eat
            – heavily inspired from the Levant region. This dish called the
            Wishaiq is popular among other countries in the Middle East as well.
            Wishaiq is made from chopping meat into thick strands, which are
            then salted and hung on ropes to dry. This meat preparation can be
            stored for long periods of time and is normally cooked whenever
            needed. You can accompany it with spiced sauces if you’d like.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/4.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Matazeez – Qassim</Text> This
            famous dish from Qassim is made by cutting dough into small pieces.
            These pieces are then flattened and put in a pot that contains a
            meat broth. Sometimes local truffle and ghee are added in the
            mixture as well. Matazeez is a normally eaten during lunch or
            dinner. A small bite of this bread can instantly cheer you up.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/5.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Thireed – Hail </Text>
            Traditionally bread has been more popular in Arabia when compared to
            rice. This dish also is a type of bread. The Thireed is made by
            grilling the dough on fire or charcoal, which is later mixed with
            onions and ghee. Since it’s on the healthier side, eating the
            Thireed is like having a gastronomic adventure in which you won’t
            have to worry about the consequences later.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/6.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Bur/Samen – Najran </Text>
            Influenced heavily by Yemeni culture, the dishes in Najran are quite
            similar to those across the border in Yemen. The dish is usually
            eaten as breakfast. Dough is baked and placed in a container. Then,
            a hole is made in its center and ghee is poured inside it. Some
            families use honey or milk instead of ghee. Now this is something to
            drool over.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/7.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Sileeq – Makkah</Text> Being a
            melting pot of cultures, Makkan cuisine is influenced by the wide
            range of pilgrims it has seen over the years. Sileeq is prepared by
            boiling meat in water with various spices that results in a broth.
            This broth is then used to cook rice. Ghee is added right at the
            very end before serving.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/8.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Jereesh – Riyadh</Text> The
            Riyadh Region has a wide variety of traditional recipes, most of
            which have been passed down from generation to generation. The
            Jereesh is a side dish that is a regular feature in many households
            in the central part of the country. It is made by mixing groats with
            milk at high temperatures resulting in a concoction that has
            exquisite texture and taste of course
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/9.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Mandi – Tabuk</Text> Though
            popular all across Saudi, Tabuk’s Mandi is noteworthy. Mandi is made
            by putting a whole lamb with or without rice in an oven. The oven is
            then covered to prevent steam from exiting. After it has been left
            for around 2 hours in the oven, the delectable Mandi is ready to
            eat. Sometimes herbs are sprinkled on top before serving. Talk about
            something that is fragrant and succulent.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/10.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Aseedah –Baha</Text> Famous even
            beyond the Kingdom’s borders, the luscious Aseedah is Baha’s pride.
            This savory concoction is made from either corn or wheat flour.
            Yoghurt and broth are mixed steadily until the mixture thickens.
            This is then served with butter or margarine. A thickened version of
            this dish is known as Al Aish.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/11.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Kibdah- Madinah</Text> The
            famous dish that is found all over Saudi has been part of the
            traditional cuisine of the residents of Madinah for decades now.
            Kibdah is prepared by stir-frying lamb liver with onions, tomatoes,
            and various spices. No connoisseurs are required here; it’s always
            delectable regardless of who makes it.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/food/12.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5, marginBottom: 20 }}>
            <Text style={{ fontWeight: "800" }}>
              Muhammar – Eastern Province
            </Text>
            Popular over the other side of the coast in neighboring Bahrain as
            well, Muhammar is a sweet rice dish that is imbued with assorted
            spices and date palm. Relish this with grilled fish.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default PopularFood;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
});
