import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import React from "react";

const PopularTermsAndProverbs = () => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          fontSize: 24,
          textAlign: "center",
        }}
      >
        Popular Terms And Proverbs
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}
      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Text style={styles.text}>Western Hejazi province</Text>
        <View style={{ marginTop: 6 }}>
          <Text style={styles.SubText}>
            {`brushing : اكد
        skirt :  قنله
        dress : كرته
        hit you : اهبدك`}
          </Text>
        </View>

        <View
          style={{
            borderBottomColor: "lightgray",
            marginVertical: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />

        {/* section 2 */}
        <Text style={styles.text}>Central najd province</Text>
        <View style={{ marginTop: 6 }}>
          <Text style={[styles.SubText, { textAlign: "left" }]}>
            {`I know : ابخص
        getting in a bad situation and not knowing what to do :  ابتلش
        welcome/go in : اقلط
        moving on : ابتل
        running : ابعص
        `}
          </Text>
        </View>

        <View
          style={{
            borderBottomColor: "lightgray",
            marginVertical: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />

        {/* section 3 */}
        <Text style={styles.text}>Southern province</Text>
        <View style={{ marginTop: 6 }}>
          <Text style={styles.SubText}>
            {`leave : افلح
        get away :  انقلع
        how are you : ولعون لك
        little rain : غشقه
        here : هنيه
        `}
          </Text>
        </View>

        <View
          style={{
            borderBottomColor: "lightgray",
            marginVertical: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />

        {/* section 4 */}
        <Text style={styles.text}>Northern province</Text>
        <View style={{ marginTop: 6 }}>
          <Text style={styles.SubText}>
            {`leave/go : قوطر
        to make fun of someone :  طقطقه
        someone who talks too much : الصديع
        open : ابعد
        someone who copies others too much : معة
        `}
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default PopularTermsAndProverbs;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
  text: {
    color: "white",
    fontWeight: "600",
    fontSize: 16,
    marginTop: 5,
  },
  SubText: {
    color: "white",
  },
});
