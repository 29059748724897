import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import React from "react";

const HistoricalSites = ({ navigation }) => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          marginTop: 10,
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Saudi Arabia's Historical Sites
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}

      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Text style={{ color: "white" }}>
          Saudi Arabia is recognized for many things, such as its historical
          sites and tourist attractions. Saudi Arabia holds six astonishing
          UNESCO World Heritage sites, which serve as a testament to the
          nation's rich and varied background as well as its preservation
          efforts.
        </Text>

        {/* Sections */}

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/historical/1.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>1- Al Masmak Fortress:</Text> a
            palace located in Riyadh, the capital city, as a tourist attraction
            that has witnessed significant events in the kingdom’s history. The
            fortress was originally constructed in the Hijri 14th century during
            Imam Abdullah bin Faisal’s reign as his residence and the hub of
            power and wealth.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/historical/2.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>2- Tayebat City Museum: </Text>
            Tayebat City Museum transports tourists back in time with its
            2,500-year legacy of fishing tribes, pilgrims, and Arabian Trade.
            The museum provides a living history museum on the origins of
            Jeddah, Saudi Arabia, and even the Arabian Peninsula! A whole floor
            is also devoted to the rich Islamic history of the city.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/historical/3.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>3- Al Masjid an Nabawi:</Text>{" "}
            also referred to as the ‘Prophet's Mosque’, is a historic mosque in
            Medina that was first established and constructed by the prophet
            Muhammad. Al Masjid an Nabawi was the second mosque built in the
            Islamic history, and is now one of the largest mosques in the world!
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/historical/4.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>
              4- Tabuk Archaeological Castle:
            </Text>{" "}
            which is one of the most prominent historical castles and popular
            tourist destinations in the area, having been built over five
            centuries ago. It was built in 1559. It is distinguished by its
            exquisite architectural design. It has undergone multiple
            restorations in the past, but Saudi Arabia has paid particular care
            to it, renovating it twice such that it is now a popular destination
            for travelers and researchers.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/historical/5.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>5- Al Uqayr:</Text> a historic
            port town located in Al-Ahsa Governorate of the Eastern Province of
            Saudi Arabia. In addition, the conference at the Uqair Protocol of
            1922, which contributed to the establishment of modern-day Saudi
            Arabia's borders, took place at this same location.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/historical/6.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5, marginBottom: 20 }}>
            <Text style={{ fontWeight: "800" }}>6- Hegra:</Text> located in
            modern-day ‘AlUla’, the Hegra site was the location of the Nabataean
            city from the end of the first millennium BCE to the beginning of
            the first millennium CE, as there is evidence of human activity
            dating back beyond the first millennium BCE. Today, Hegra is most
            famous for its more than 110 colossal tombs that were carved out of
            rock formations and used to house the elite of the Nabataeans.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default HistoricalSites;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
});
