import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import React from "react";

const Kings = () => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          marginTop: 10,
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Saudi Kings
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}
      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/1.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>
              King Abdulaziz (1932-1953)
            </Text>{" "}
            During his rule, King Abdulaziz started building the country’s
            infrastructure. He established roads and basic communications
            systems, introduced modern technology, and improved education,
            health care and agriculture.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/2.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>King Saud (1953-1964)</Text>{" "}
            Abdulaziz’ eldest son Saud acceded to the throne upon his father’s
            death in 1953. He continued King Abdulaziz’s legacy, creating the
            Council of Ministers and establishing the Ministries of Health,
            Education and Commerce. One of King Saud’s greatest successes was
            the development of education – under his rule many schools were
            established in the Kingdom, including its first institute of higher
            education, King Saud University, in 1957. King Saud also made his
            mark globally. In 1957, he became the first Saudi monarch to visit
            the United States. In 1962 he sponsored an international Islamic
            conference that would become the Muslim World League, headquartered
            in Makkah.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/3.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>King Faisal (1964-1975)</Text>{" "}
            King Faisal bin Abdulaziz was a visionary innovator with a great
            respect for tradition. He initiated the first of a series of
            economic and social development plans that would transform Saudi
            Arabia’s infrastructure, especially industry, and set the Kingdom on
            a path of rapid growth. He also established the first public schools
            for girls.In foreign policy, King Faisal showed a firm commitment to
            the Islamic world. He was a central force behind the establishment
            in Jeddah in 1971 of the Organization of the Islamic Conference
            (OIC), a group of 56 Islamic countries that promotes Islamic unity
            and cooperation.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/4.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>King Khalid (1975-1982)</Text>{" "}
            Khalid bin Abdulaziz succeeded King Faisal in 1975. King Khalid also
            emphasized development, and his reign was marked by an almost
            explosive growth in the country’s physical infrastructure. It was a
            period of enormous wealth and prosperity for Saudi Arabia. On the
            international stage, King Khalid was a prime mover in forming the
            Gulf Cooperation Council (GCC) in 1981, an organization that
            promotes economic and security cooperation among its six member
            countries: Bahrain, Kuwait, Oman, Qatar, the United Arab Emirates
            and Saudi Arabia.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/5.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>King Fahd (1982-2005)</Text>{" "}
            King Fahd was central to Saudi Arabia’s efforts to diversify its
            economy and promote private enterprise and investment. He
            restructured the Saudi government and approved the first nationwide
            municipal elections, which took place in 2005. One of King Fahd’s
            greatest accomplishments in Saudi Arabia was a series of projects to
            expand the Kingdom’s facilities to accommodate the millions of
            pilgrims who come to the country each year. These projects involved
            major expansions of Islam’s two holiest sites, the Holy Mosque in
            Makkah and the Prophet’s Mosque in Madinah, as well as airports and
            ports. In the international arena, King Fahd worked actively to
            resolve regional and global crises. These crises included the
            Arab-Palestinian conflict, Iraq’s invasion of Kuwait, the Lebanese
            civil war in addition to conflicts in Bosnia-Herzegovina, Kosovo,
            Chechnya, Afghanistan, Somalia and Kashmir. King Fahd was also
            concerned with humanitarian issues. Under his rule, Saudi Arabia
            provided emergency humanitarian assistance to numerous countries,
            including Somalia, Bosnia and Afghanistan, as well as countries
            suffering from natural disasters, such as earthquakes (Turkey in
            1999, Iran in 2003) and the tsunami that struck Southeast Asia in
            December 2004.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/6.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>
              King Abdullah (2005 - 2015)
            </Text>{" "}
            Custodian of the Two Holy Mosques King Abdullah bin Abdulaziz
            acceded to the throne after the death of King Fahd on August 1,
            2005. As Crown Prince, he traveled widely in the Kingdom and
            inaugurated a number of projects throughout the country. In 2005 he
            closely monitored the election process for the country’s municipal
            councils. The Prince’s first official visit to the United States was
            in 1976 when he met with President Gerald Ford. Since then, he has
            made a number of visits to the United States, including his most
            recent on June 29, 2010 when he met with President Barak Obama at
            the White House. His international diplomacy reflects Saudi Arabia’s
            leadership role in defense of Arab and Islamic issues and for the
            achievement of world peace, stability and security. Peace in the
            Middle East and the plight of the Palestinians are of particular
            concern to King Abdullah. His proposal for a comprehensive
            Arab-Israeli peace, presented at the Beirut Arab Summit in 2002, has
            been adopted by the League of Arab States and is known as the Arab
            Peace Initiative. King Abdullah has been unwavering in his
            condemnation of terrorism. At the International Counterterrorism
            Conference in Riyadh in February, 2005, he called for greater
            international cooperation to fight this global problem.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/kings/7.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5, marginBottom: 20 }}>
            <Text style={{ fontWeight: "800" }}>King Salman (2015 - )</Text>{" "}
            Custodian of the Two Holy Mosques King Salman bin Abdulaziz acceded
            to the throne after the death of King Abdullah on January 23, 2015.
            King Salman Salman was designated Crown Prince of the Kingdom of
            Saudi Arabia, and appointed Deputy Prime Minister, by Custodian of
            the Two Holy Mosques King Abdullah bin Abdulaziz on June 18, 2012,
            upon the death of Crown Prince Nayef bin Abdulaziz. Crown Prince
            Salman also served as Minister of Defense. Since 1956, Prince Salman
            has chaired various humanitarian and service committees that provide
            relief from natural and man-made disasters. For his humanitarian
            services, he has been awarded many medals and decorations, including
            awards from Bahrain, Bosnia and Herzegovina, France, Morocco,
            Palestine, the Philippines, Senegal, the United Nations, Yemen, and
            the King Abdulaziz Medal - First Class. He is a recipient of several
            honorary degrees and academic awards, including an honorary
            doctorate from the Islamic University of Madinah, the Prince Salman
            academic award, and the Kant Medal by the Berlin-Brandenburg Academy
            of Sciences and Humanities in appreciation of his contributions to
            the field of science.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default Kings;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
});
