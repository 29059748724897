import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import React from "react";

const PopularSongsAndDances = () => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          marginTop: 10,
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Saudi Popular Dances and Songs
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}

      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Text style={{ color: "white" }}>
          Saudi Arabia is known for its many traditional dances that symbolise
          cultural diversity between the 13 provinces. Each province has a
          unique cultural dance that is appreciated and cherished. Additionally,
          there are a wide range of beautiful songs that the Saudi nation loves
          to hear.
        </Text>

        {/* sections */}
        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/songs/1.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Alkatoah Dance</Text> The art of
            the ancient war dance known as the Saudi South Traditional Dance is
            distinguished by its profusion, diversity of tunes, and tranquil
            rhythm.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/songs/2.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Samri dance </Text>Many people
            love this dance, especially women and girls, since it is a
            traditional music and dance form that has its roots in Najd, Saudi
            Arabia. It entails two rows of men seated on their knees who sing
            poetry while the daff drum is frequently played and they sway and
            clap in time with the music.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/songs/3.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>The Ardha </Text>Lastly, we have
            the most significant and renowned dance, also known as the saudi
            national dance. The Ardha is a centuries-old custom that originated
            in the Najd, the country's center. It consists of a poet or
            storyteller, singers, and sword-carrying dancers.
          </Text>
        </View>

        <View
          style={{
            borderBottomColor: "lightgray",
            marginVertical: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />
        <Text style={{ color: "white" }}>
          Saudi Arabian Musical traditions has its share of famous songs that
          are celebrated and loved across the country. We take great pride in
          the genuineness and diversity of our nation's regional cultures. Every
          area has a distinctive song, some of which are:
        </Text>

        <View style={{ marginTop: 10 }}>
          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Najdi Music</Text>The Najd
            region, in the center of Saudi Arabia, is renowned for its strong,
            rhythmic music. The mesmerizing beats of spoken poetry and Najdi
            music frequently accompany the traditional dance style Ardah, also
            referred to as the "sword dance," making for an engrossing auditory
            experience.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>THejazi Music </Text>The melodic
            'Sambri' music originates in the western part of Hejaz. Hejazi
            music, which is characterized by the mesmerizing melodies of the
            'Oud' or lute, is frequently played as a background for poetry
            readings, resulting in a pleasing fusion of music and words
          </Text>

          <View style={{ alignItems: "center", flex: 1, marginBottom: 20 }}>
            <Image
              source={require("../assets/songs/4.jpg")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default PopularSongsAndDances;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
});
