import { StyleSheet } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeScreen from "./screens/HomeScreen";
import PopularTermsAndProverbs from "./screens/PopularTermsAndProverbs";
import PopularFood from "./screens/PopularFood";
import HistoricalSites from "./screens/HistoricalSites";
import PopularSongsAndDances from "./screens/PopularSongsAndDances";
import PopularCostumes from "./screens/PopularCostumes";
import PopularCrafts from "./screens/PopularCrafts";
import TraditionAndCrafts from "./screens/TraditionAndCustomes";
import Kings from "./screens/Kings";

const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="Home"
      >
        <Stack.Screen name="Home" component={HomeScreen} />

        <Stack.Screen name="Terms" component={PopularTermsAndProverbs} />

        <Stack.Screen
          name="PopularFood"
          component={PopularFood}
          options={{
            animation: "slide_from_bottom",
          }}
        />

        <Stack.Screen
          name="HistoricalSites"
          component={HistoricalSites}
          options={{
            animation: "slide_from_bottom",
          }}
        />

        <Stack.Screen
          name="PopularSongsAndDances"
          component={PopularSongsAndDances}
          options={{
            animation: "slide_from_bottom",
          }}
        />

        <Stack.Screen
          name="PopularCostumes"
          component={PopularCostumes}
          options={{
            animation: "slide_from_bottom",
          }}
        />

        <Stack.Screen
          name="PopularCrafts"
          component={PopularCrafts}
          options={{
            animation: "slide_from_bottom",
          }}
        />

        <Stack.Screen
          name="TraditionAndCustoms"
          component={TraditionAndCrafts}
          options={{
            animation: "slide_from_bottom",
          }}
        />

        <Stack.Screen
          name="Kings"
          component={Kings}
          options={{
            animation: "slide_from_bottom",
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({});
