import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import React from "react";

const TraditionAndCrafts = () => {
  return (
    <View style={styles.ScreenContainer}>
      <Text
        style={{
          color: "white",
          fontWeight: 500,
          marginTop: 10,
          fontSize: 20,
          textAlign: "center",
        }}
      >
        Saudi Arabia's Customs and Traditions
      </Text>

      {Platform.OS === "web" && (
        <TouchableOpacity onPress={() => navigation.popToTop()}>
          <a
            href="/"
            style={{
              color: "white",
              fontWeight: 500,
              marginTop: 10,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Back
          </a>
        </TouchableOpacity>
      )}

      <ScrollView
        style={{
          marginTop: 20,
          flex: 1,
          backgroundColor: "#000000c0",
          padding: 10,
          borderRadius: 10,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/traditionandcustomes/1.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>
              Arab and Islamic Traditions
            </Text>{" "}
            Saudi Arabia’s traditions are rooted in Islamic teachings and Arab
            customs. Highlights of the year include the Hajj (pilgrimage) season
            and the holy month of Ramadan, along with the national holidays that
            coincide with each event. Throughout the holy month of Ramadan,
            Muslims fast from sunrise to sunset, followed by the ‘Eid al Fitr’
            holiday. Every year, millions of muslims from all over the world
            travel to Makkah during the Pilgrimage season, ending it with ‘Eid
            Al Adha’. Generosity and hospitality are two important Arab
            traditions performed by Saudis. Arabic coffee is typically served in
            small cups with dates and sweets as a welcome gesture to guests,
            friends, or family. Furthermore, preparing Arabic coffee is also a
            cultural custom.
          </Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <View style={{ alignItems: "center", flex: 1 }}>
            <Image
              source={require("../assets/traditionandcustomes/2.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <View style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800", color: "white" }}>Festivals</Text>
            <Text style={{ color: "white" }}>
              1- Janadriyah Festival: An annual and the oldest and most
              traditional of all festivals. Among the festivals and events in
              Riyadh, this one is a crucial cultural celebration. It is often
              held from the 12th to the 26th of February every year. Over the
              course of two weeks, there are a variety of cultural events
              including folklore art exhibits, poetry recitations, camel racing,
              and other events.
            </Text>
            <Text style={{ color: "white" }}>
              2- Riyadh Spring Festival: This is a great time to plan your trip
              to Saudi Arabia! An event which spans ten days of celebrating
              nature and the Spring Season in Riyadh starting from March.
            </Text>
            <Text style={{ color: "white" }}>
              3- Sharqia Season Festival: The Sharqiyah Season Festival is a
              10-day, diverse cultural event that features a wide range of
              foreign artists, traditional performances, movie screenings, and
              sporting events. A fruitful way to enhance your knowledge and
              honor Saudi Arabia’s heritage and culture.
            </Text>
          </View>
        </View>

        <View
          style={{
            borderBottomColor: "lightgray",
            marginVertical: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />

        <View style={{ marginTop: 10 }}>
          <Text style={{ color: "white", marginTop: 5 }}>
            <Text style={{ fontWeight: "800" }}>Language</Text>
            Arabic is the official language of Saudi Arabia and most Middle
            Eastern countries. All Arab nations use written Classical Arabic as
            their standard writing system. It is the language of the Holy Quran,
            the sacred book of Islam and has altered a little over the
            centuries. Spoken Arabic, however, differs in a variety of dialects
            depending on the country. English is also a crucial language in
            Saudi Arabia, spoken as the second language and taught in schools.
          </Text>
        </View>

        <View
          style={{
            borderBottomColor: "lightgray",
            marginVertical: 10,
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />

        <View style={{ marginTop: 10 }}>
          <Text style={{ fontWeight: "800", color: "white" }}>
            Traditional Dress
          </Text>
          <View style={{ alignItems: "center", flex: 1, marginTop: 10 }}>
            <Image
              source={require("../assets/traditionandcustomes/3.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>
          <Text style={{ color: "white", marginTop: 5 }}>
            Men: Men dress in thawbs, which are cotton or wool ankle-length
            shirts. They wear a huge cotton square (shumagh) on their heads,
            folded over a white cap and secured with a cord circlet (igaal). The
            overall look concludes with the flowing, full-length outer cloak
            (bisht), which is typically made of wool or camel hair. In the past,
            a bisht was used as blankets while traveling.
          </Text>

          <View style={{ alignItems: "center", flex: 1, margintop: 10 }}>
            <Image
              source={require("../assets/traditionandcustomes/4.png")}
              resizeMode="cover"
              style={{ width: 200, height: 200 }}
            />
          </View>

          <Text style={{ color: "white", marginBottom: 20 }}>
            Women: Women traditionally wear a modest black outer cloak (abaya)
            over their clothes. They tend to cover their hair with a scarf
            (tarha/hijab) due to modesty. Some Saudi women wear veils (niqab)
            made of sheer material.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

export default TraditionAndCrafts;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#054314",
    padding: 20,
  },
});
