import {
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";

const image = require("../assets//home/download.jpg");
const image2 = require("../assets/home/1.jpg");
const image3 = require("../assets/home/2.jpg");
const image4 = require("../assets/home/3.jpg");
const image5 = require("../assets/home/4.jpg");
const image6 = require("../assets/home/5.jpg");
const image7 = require("../assets/home/6.jpg");

const HomeScreen = ({ navigation }) => {
  return (
    <View style={styles.ScreenContainer}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.ScrollViewFlex}
      >
        <View style={{ justifyContent: "center", gap: 20 }}>
          <TouchableOpacity onPress={() => navigation.navigate("Terms")}>
            <View style={[styles.CardImage, { backgroundColor: "lightgray" }]}>
              <Text style={styles.text}>Saudi Popular Terms and Proverbs</Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate("PopularFood")}>
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image2}
            >
              <Text style={styles.text}>Saudi Popular Food</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("HistoricalSites")}
          >
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image3}
            >
              <Text style={styles.text}>Saudi Historical Sites</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("PopularSongsAndDances")}
          >
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image4}
            >
              <Text style={styles.text}>Saudi Popular Songs and Dances</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("PopularCostumes")}
          >
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image5}
            >
              <Text style={styles.text}>Saudi Popular Costumes</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("PopularCrafts")}
          >
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image6}
            >
              <Text style={styles.text}>Saudi Popular Crafts</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("TraditionAndCustoms")}
          >
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image7}
            >
              <Text style={styles.text}>Saudi Customs and Traditions</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate("Kings")}>
            <ImageBackground
              resizeMode="cover"
              style={styles.CardImage}
              source={image}
            >
              <Text style={styles.text}>Saudi Kings</Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  ScreenContainer: {
    flex: 1,
    backgroundColor: "#0C0F14",
    padding: 40,
    alignItems: "center",
  },
  ScrollViewFlex: {
    flexGrow: 1,
    gap: 10,
  },
  CardImage: {
    width: 300,
    height: 300,
    borderRadius: 20,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },

  text: {
    color: "white",
    fontSize: 24,
    lineHeight: 38,
    width: "100%",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#000000c0",
  },
});
